import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

export const ShareDialog = (props: DialogProps) => {
  const { t } = useTranslation();

  const copyIntoClipboardHandler = async e => {
    try {
      await window.navigator.clipboard.writeText(window.location.href);
    } catch (e) {
      console.error("Could not write to clipboard!", e);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t("Share")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("your story is accessible from anywhere")}
        </DialogContentText>
        <TextField
          fullWidth
          label={t("Your share link")}
          onChange={() => {}}
          value={window.location.href}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={copyIntoClipboardHandler} color="primary">
          {t("Copy into clipboard")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
