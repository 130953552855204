import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { randomAlphanumeric } from "../utils/randomAlphanumeric";
import { useLocation } from "react-router-dom";

type StoryIdGeneratorProps = {} & RouteComponentProps<{ storyId: string }>;

export const StoryIdGenerator = (props: StoryIdGeneratorProps) => {
  const location = useLocation();
  if (location.search.startsWith("?"))
    return <Redirect to={location.search.slice(1)} />;
  else return <Redirect to={randomAlphanumeric(8)} />;
};
