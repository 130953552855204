import React from "react";
import { createGlobalStyle } from "styled-components";

document.documentElement.style.setProperty(
  "--mobile-vh-without-navbar",
  window.innerHeight * 0.01 + "px"
);

export default createGlobalStyle`
* {
    box-sizing: border-box;
    //scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height:100%;
}

*::-webkit-scrollbar {
    width: 5px;
    background-color: #00000038;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(59, 38, 103, 0.65);
}

.MuiAlert-filledSuccess {
  background-color: #12001b9e;
}
.MuiAlert-filledError {
  background-color: rgba(100,5,13,0.32);
}

.MuiDialog-paper {
  border: 1px solid #9d00ff38;
}

   `;
