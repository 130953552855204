import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "&redux/store/Store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import styled from "styled-components";
import { markTutorialCompleted } from "../redux/reducers/persistent";

const StyledMenuBookIcon = styled(MenuBookIcon)`
  float: left;
  padding: 0 20px 10px 0px;
  height: 60px;
  width: 60px;
`;

export const Tutorial = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isTutorialCompleted = useSelector(
    (store: Store) => store.persistent.isTutorialCompleted
  );

  const onTutorialClose = () => {
    dispatch(markTutorialCompleted());
  };

  return (
    <Dialog open={!isTutorialCompleted} onClose={onTutorialClose}>
      <DialogTitle>{t("Heya!")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "this is limya"
          )}
          <br></br>
          <br></br>
          <StyledMenuBookIcon />
          {t(
            "there is a book icon in the bottom left corner of your screen"
          )}
          <br></br>
          <br></br>
          {t(
            "there you can save your story and get your storys link"
          )}
          <br></br>
          <br></br>
          {t("Happy writing!")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onTutorialClose} data-testid={"close-tutorial-button"}>
          {t("awesome now let me write")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
