import { root as rootReducer } from "./reducers/root";
import { createEpicMiddleware } from "redux-observable";
import { root } from "./epics/root";
import persistState from "redux-localstorage";
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";


const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    middleware: [epicMiddleware, ...getDefaultMiddleware()],
    enhancers: [persistState("persistent")],

});

epicMiddleware.run(root);
