import React from "react";
import { StoryIdGenerator } from "./StoryIdGenerator";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StoryEditor } from "./StoryEditor";
import {NotifierXyz} from "./Temporary/NotifierXyz";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/:storyId" component={StoryEditor} />
          <Route path="*" component={StoryIdGenerator} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
