import React from "react";
import styled from "styled-components";
import { ReactComponent as ButterflySvg } from "../assets/images/butterfly.svg";
import { LinearProgress } from "@material-ui/core";
import { animated } from "react-spring";
import { CSSTransition } from "react-transition-group";

const Loader = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${props => props.theme.zIndexes.loading};
  background-color: #010007;
  display: grid;
  place-items: center;
  opacity: 1;

  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
`;

export const LoadingScreen = (props: { in: boolean }) => {
  return (
    <CSSTransition
      in={props.in}
      timeout={400}
      classNames={"fade"}
      unmountOnExit
    >
      <Loader />
    </CSSTransition>
  );
};
