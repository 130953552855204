import { createMuiTheme, Theme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
  export interface ThemeOptions extends Partial<CustomTheme> {}
  export interface Theme extends CustomTheme {}
}
declare module "styled-components" {
  export interface DefaultTheme extends CustomThemeAndMuiTheme {}
}

interface CustomTheme {
  zIndexes: {
    mainBackground: number;
    overlay: number;
    vignette: number;
    loading: number;
  };
}

type CustomThemeAndMuiTheme = Theme & CustomTheme;

export const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "rgba(0, 0, 0, 0.79)",
      paper: "rgba(0, 0, 0, 0.79)"
    },
    text: {
      primary: "#807eff",
      secondary: "hsla(240,24%,89%,0.92)"
    }
  },
  typography: {
    fontFamily: "Anonymous Pro"
  },
  zIndexes: {
    mainBackground: -100,
    overlay: 100,
    vignette: 101,
    loading: 10000
  }
});