import React from "react";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { theme } from "../theme";
import { Provider } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import GlobalStyles from "../Components/GlobalStyles";
import "../i18n/init";
import { FirebaseContextProvider } from "../firebase/FireappContext";

export const StandardConfiguration = ({ children, store }) => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <FirebaseContextProvider>
              <CssBaseline />
              <GlobalStyles />
              {children}
            </FirebaseContextProvider>
          </Provider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};
