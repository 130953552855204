import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { requestChangeBackground } from "&redux/reducers/ui";

type CustomBackgroundDialogProps = DialogProps & {};

export const CustomBackgroundDialog = (props: CustomBackgroundDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [bgrLink, setBgrLink] = useState("");
  const onBgrLinkChange = e => setBgrLink(e.target.value);

  const { storyId } = useParams<{ storyId: string }>();
  const onChangeBackgroundClicked = e => {
    dispatch(requestChangeBackground({ backgroundLink: bgrLink, storyId }));
  };

  const isProperLink = () => {
    return [".jpg", ".gif", ".png", ".jpeg"].some(x => bgrLink.endsWith(x));
  };

  return (
    <Dialog
      {...props}
    >
      <DialogTitle>{t("Custom background")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans i18nKey={"Paste the link to your custom background here"}>
            Paste the link to your custom background here.
            <br /> It should be either a
            <span style={{ color: "#7ee0d3" }}>.jpg</span> or
            <span style={{ color: "#7ee0d3" }}>.png</span> file.
          </Trans>
        </DialogContentText>
        <TextField
          inputProps={{
            "data-testid": "custom-background-link-input"
          }}
          autoFocus
          fullWidth
          label={t("A link, preferably ending with jpg or png")}
          margin="dense"
          value={bgrLink}
          onChange={onBgrLinkChange}
          error={!(bgrLink && isProperLink())}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onChangeBackgroundClicked}
          data-testid={"change-background-button"}
        >
          {t("Change background")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
