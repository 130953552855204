import React, { useState } from "react";
import {
  Drawer,
  DrawerProps,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListProps,
  Typography,
  ListItemProps,
  TypographyProps
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import ImageIcon from "@material-ui/icons/Image";
import styled from "styled-components";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { useTranslation } from "react-i18next";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { CustomBackgroundDialog } from "./CustomBackgroundDialog";
import LinkIcon from "@material-ui/icons/Link";
import { ShareDialog } from "./ShareDialog";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const OpenDrawerButton = styled(IconButton)<
  IconButtonProps & { hidden: boolean }
>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  border-radius: 0 50% 0 0;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  background-color: #00000038;

  transition: opacity 0.3s;
  opacity: ${props => Number(!props.hidden)};

  & > .MuiIconButton-label > .MuiSvgIcon-root {
    height: 80%;
    width: 80%;
    color: #9183e2;
  }
`;

const SmallTypography = styled(Typography)<TypographyProps>`
  font-size: 0.8rem;
`;

const StyledDrawer = styled(Drawer)<DrawerProps>`
  .MuiPaper-root {
    border-right: 1px solid #9d00ff38;
  }

  .MuiSvgIcon-root {
    color: #a600ff;
  }
`;

const StyledList = styled(List)<ListProps>``;

const StyledListItem = styled(ListItem)<ListItemProps>`
  padding-right: 40px;
`;

interface SideMenuProps {
  onStorySave;
  onRefresh;
}

export const SideMenu = (props: SideMenuProps) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const openHandle = () => setOpen(true);
  const closeHandle = () => setOpen(false);

  const [
    isCustomBackgroundDialogOpen,
    setCustomBackgroundDialogOpen
  ] = useState(false);
  const customBackgroundHandler = () => setCustomBackgroundDialogOpen(true);
  const onCustomBackgroundClose = () => setCustomBackgroundDialogOpen(false);

  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const shareOpen = () => setShareDialogOpen(true);
  const shareClose = () => setShareDialogOpen(false);

  const [isFullscreen, setFullScreen] = useState(false);
  const fullscreenClickedHandler = async e => {
    if (isFullscreen) {
      await document.exitFullscreen();
      setFullScreen(false);
    } else {
      await document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  };

  return (
    <>
      <OpenDrawerButton
        hidden={isOpen}
        onClick={openHandle}
        data-testid={"open-side-menu-button"}
      >
        <MenuBookIcon />
      </OpenDrawerButton>
      <StyledDrawer anchor="left" open={isOpen} onClose={closeHandle}>
        <StyledList>
          {document.fullscreenEnabled && (
            <StyledListItem
              button
              onClick={fullscreenClickedHandler}
              data-testid={"fullscreen-button"}
            >
              <ListItemIcon>
                <FullscreenIcon />
              </ListItemIcon>
              <ListItemText secondary={t("Toggle fullscreen on or off")}>
                {t("Fullscreen")}
              </ListItemText>
            </StyledListItem>
          )}

          <StyledListItem button onClick={props.onStorySave}>
            <ListItemIcon>
              <SaveIcon />
            </ListItemIcon>
            <ListItemText secondary={t("Store your story on the server")}>
              {t("Save online")}
            </ListItemText>
          </StyledListItem>
          <StyledListItem button onClick={customBackgroundHandler}>
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText secondary={t("Upload your own background")}>
              {t("Custom background")}
            </ListItemText>
          </StyledListItem>
          <StyledListItem button onClick={props.onRefresh}>
            <ListItemIcon>
              <RefreshIcon />
            </ListItemIcon>
            <ListItemText secondary={t("Get the latest version of your story")}>
              {t("Refresh")}
            </ListItemText>
          </StyledListItem>
          <StyledListItem button onClick={shareOpen}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText secondary={t("Share your story via a link")}>
              {t("Share")}
            </ListItemText>
          </StyledListItem>
          <StyledListItem button onClick={closeHandle}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>{t("Close the menu")}</ListItemText>
          </StyledListItem>
        </StyledList>
        <div style={{ marginTop: "auto", marginBottom: "50px" }}>
          <SmallTypography align="center">
            {t("Got any suggestions?")}
          </SmallTypography>
          <SmallTypography align="center">
            {t("Shoot us a message!")}
          </SmallTypography>
          <SmallTypography align="center">limya@limya.pl</SmallTypography>
        </div>
      </StyledDrawer>
      <CustomBackgroundDialog
        open={isCustomBackgroundDialogOpen}
        onClose={onCustomBackgroundClose}
      />
      <ShareDialog open={isShareDialogOpen} onClose={shareClose} />
    </>
  );
};
