import * as fire from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

export const fireapp = fire.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    appId:process.env.REACT_APP_FIREBASE_APP_ID
});

fireapp.analytics();

if (window.location.hostname === "localhost") {
    fireapp.firestore().settings({
        host: "localhost:8080",
        ssl: false,
        experimentalForceLongPolling:true
    });
    console.log("The fireapp is connected to an emulator now.");
}