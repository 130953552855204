import React, { useContext} from "react";
import { createContext } from "react";
import * as fire from "firebase/app";
import { fireapp } from "./fireapp";

interface FirebaseContextType {
  fireapp: fire.app.App;
}

export const FireappContext = createContext<FirebaseContextType>({
  fireapp: fireapp
});

export const FirebaseContextProvider = ({ children }) => {
  return (
    <FireappContext.Provider value={{ fireapp: fireapp }}>
      {children}
    </FireappContext.Provider>
  );
};
export const useFireapp = () => useContext(FireappContext).fireapp;
