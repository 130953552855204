import { combineEpics, ofType } from "redux-observable";
import { catchError, filter, map, tap } from "rxjs/operators";
import { fireapp } from "../../firebase/fireapp";
import { of } from "rxjs";
import { requestChangeBackground, ui } from "&redux/reducers/ui";

const backgroundChange = action$ =>
  action$.pipe(
    filter(requestChangeBackground.match),
    tap(async (action: ReturnType<typeof requestChangeBackground>) => {
      await fireapp.firestore().runTransaction(async transaction => {
        const docRef = fireapp
          .firestore()
          .collection("stories")
          .doc(action.payload.storyId);
        return transaction.get(docRef).then(doc => {
          transaction.set(
            docRef,
            { backgroundLink: action.payload.backgroundLink },
            { merge: true }
          );
        });
      });
    }),
    map((x: ReturnType<typeof requestChangeBackground>) =>
      ui.actions.changeBackground({ backgroundLink: x.payload.backgroundLink })
    ),
    catchError(err => of({ type: "BackgroundChangeError" }))
  );

export const root = combineEpics(backgroundChange);
