import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { RouteComponentProps } from "react-router-dom";
import Draft, {
  Editor,
  EditorState,
  convertToRaw,
  Modifier,
  convertFromRaw,
  RichUtils,
  EditorProps,
  CompositeDecorator,
  AtomicBlockUtils
} from "draft-js";
import { useFireapp } from "../firebase/FireappContext";
import styled, { createGlobalStyle } from "styled-components";
import { SideMenu } from "./SideMenu";
import {Button, IconButton, IconButtonProps, Snackbar} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "&redux/store/Store";
import { LoadingScreen } from "./LoadingScreen";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Tutorial } from "./Tutorial";
import { ui } from "&redux/reducers/ui";
import background from "../assets/images/bgr_clouds.jpg";
import ImageIcon from "@material-ui/icons/Image";

type StoryEditorProps = {} & RouteComponentProps<{ storyId: string }>;


const InsertImageButton = styled(IconButton)<
  IconButtonProps
  >`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  border-radius: 50% 0 0 0;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  background-color: #00000038;

  transition: opacity 0.3s;
  opacity: ${props => Number(!props.hidden)};

  & > .MuiIconButton-label > .MuiSvgIcon-root {
    height: 80%;
    width: 80%;
    color: #9183e2;
  }
`;


const Vignette = styled.div`
  position: absolute;
  background: linear-gradient(
    90deg,
    #00000052 0%,
    transparent 30%,
    transparent 70%,
    #00000052 100%
  );
  background-size: cover;
  z-index: ${({ theme }) => theme.zIndexes.vignette};
  pointer-events: none;
  height: 100%;
  width: 100%;
`;

const EditorStyles = createGlobalStyle`
  .DraftEditor-root {
  padding: 8em 3.5em 3em;
  height:fit-content;
  width: 750px;
  max-width: 95%;
  font-size: 0.95rem !important;

  font-family: "Libre Baskerville", serif;
  background: rgba(0, 0, 0, 0.67);
  //color: #c8bdcc;
  color: hsla(235, 32%, 88%, 0.92);

  border-color: rgba(162, 193, 255, 0.14);
  border-style: solid;
  border-width: 1px;
  box-shadow: 2em 1em 1em 1em rgba(2, 10, 24, 0.377);
  border-top: 0;
  border-bottom: 0;

  @media only screen and (max-width: 1000px) {
    font-size: 95% !important;
    padding: 2.5em 2em 2em;
  }
}

.DraftEditor-editorContainer {
}

.public-DraftEditor-content {
  line-height: 165%;
  min-height: 300vh;
}

`;

const EditorContainer = styled.div.attrs<{
  backgroundOffset: number;
  backgroundLink: string;
}>(props => ({
  style: {
    backgroundPosition: `center ${props.backgroundOffset}%`
  }
}))<{ backgroundOffset: number; backgroundLink: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  color: ${props => props.theme.palette.text.secondary};
  
  background: url("${props => props.backgroundLink || background}");
  background-size: cover;
  
  & figure {
    padding: 0;
    margin: 0;
    width:100%;
    & img {
    padding: 0;
    margin: 0;
    width:100%;
    }
  }
`;

export const StoryEditor = (props: StoryEditorProps) => {
  const fireapp = useFireapp();
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [backgroundOffset, setBackgroundOffset] = useState(0);

  const getServerContentState = async () => {
    try {
      const doc = await fireapp
        .firestore()
        .collection("stories")
        .doc(props.match.params.storyId)
        .get({ source: "server" });
      const data = doc.data();
      if (doc.exists && data.contentState) return data.contentState;
      else return undefined;
    } catch (e) {
      return undefined;
    }
  };

  const Image = props => {
    if (!!props.src) {
      return <img src={props.src} />;
    }
    return null;
  };
  const Media = props => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();

    let media;

    if (type === "image") {
      media = <Image src={src} style={{ width: "100%" }} />;
    }

    return media;
  };

  const mediaBlockRenderer = useCallback(block => {
    if (block.getType() === "atomic") {
      return {
        component: Media,
        editable: false
      };
    }
    return null;
  }, []);

  const addImage = (url) => {
    setEditorState(editorState => {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "image",
        "IMMUTABLE",
        { src: url }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity
      });

      return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    });
  };

  const onImageAdd = () => {
    const url = window.prompt(t("ask_image_url"));
    addImage(url);
  }

  useEffect(() => {
    const asyncFn = async () => {
      const latestContentState = await getServerContentState();
      if (latestContentState) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(latestContentState))
        );
      }
    };
    asyncFn();
  }, []);

  const [updateError, setUpdateError] = useState(false);
  const onUpdateErrorClose = () => setUpdateError(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const onUpdateSuccessClose = () => setUpdateSuccess(false);
  const updateStory = async (state: EditorState) => {
    try {
      await fireapp.firestore().runTransaction(async transaction => {
        const docRef = fireapp
          .firestore()
          .collection("stories")
          .doc(props.match.params.storyId);
        return transaction.get(docRef).then(doc => {
          transaction.set(
            docRef,
            { contentState: convertToRaw(editorState.getCurrentContent()) },
            { merge: true }
          );
        });
      });
      setUpdateSuccess(true);
    } catch (e) {
      setUpdateError(true);
    }
  };

  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state);
  };

  /* Insert a tab whenever the user presses tab. */
  const tabHandler = e => {
    e.preventDefault();
    const state = editorState;
    const newState = Modifier.replaceText(
      state.getCurrentContent(),
      state.getSelection(),
      "\t"
    );
    setEditorState(x => EditorState.push(state, newState, "insert-characters"));
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const refreshStory = async () => {
    const latestState = await getServerContentState();
    if (latestState) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(latestState))
      );
    }
  };

  const sideMenu = useMemo(
    () => (
      <SideMenu
        onRefresh={refreshStory}
        onStorySave={() => updateStory(editorState)}
      />
    ),
    [updateStory]
  );

  const containerRef = useRef(null);
  const scrollHandler = () => {
    const h = containerRef.current as any,
      st = "scrollTop",
      sh = "scrollHeight",
      scrollOffset = (h[st] / (h[sh] - h.clientHeight)) * 100;

    setBackgroundOffset(scrollOffset);
  };

  const backgroundLink = useSelector<Store>(
    store => store.ui.backgroundLink
  ) as string;

  const dispatch = useDispatch();
  useEffect(() => {
    const fn = async () => {
      const doc = await fireapp
        .firestore()
        .collection("stories")
        .doc(props.match.params.storyId)
        .get();
      const data = doc.data();
      const bgrLink = data ? data.backgroundLink : undefined;
      dispatch(ui.actions.changeBackground({ backgroundLink: bgrLink }));
    };
    fn();
  }, []);

  const [loadingScreen, setLoadingScreen] = useState(false);
  useEffect(() => {
    const listener = () => {
      console.log("hello");
      setLoadingScreen(true);
    };
    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
  }, []);

  return (
    <>
      <LoadingScreen in={!editorState || loadingScreen} />
      <Vignette />
      {editorState && (
        <>
          <EditorStyles />
          <InsertImageButton onClick={onImageAdd}>
            <ImageIcon />
          </InsertImageButton>
          <EditorContainer
            data-testid={"background"}
            backgroundLink={backgroundLink}
            ref={containerRef}
            backgroundOffset={backgroundOffset}
            onScroll={scrollHandler}
          >
            <Editor
              blockRendererFn={mediaBlockRenderer}
              handleKeyCommand={handleKeyCommand}
              onChange={onEditorStateChange}
              editorState={editorState}
              onTab={tabHandler}
            />
          </EditorContainer>
          {sideMenu}

          <Snackbar
            open={updateError}
            autoHideDuration={20000}
            onClose={onUpdateErrorClose}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={onUpdateErrorClose}
            >
              {t("something went wrong, your story could not be saved")}
            </Alert>
          </Snackbar>
          <Snackbar
            open={updateSuccess}
            autoHideDuration={6000}
            onClose={onUpdateSuccessClose}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="success"
              onClose={onUpdateSuccessClose}
            >
              {t("Your story got saved.")}
            </Alert>
          </Snackbar>
          <Tutorial />
        </>
      )}
    </>
  );
};
