import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources: {
      "en-US": {
        translation: {
          "Custom background": "Custom background",
          "A link, preferably ending with jpg or png":
            "A link, preferably ending with .jpg or .png",
          "Change background": "Change background",
          "Paste the link to your custom background here":
            "Paste the link to your custom background here",
          "your story is accessible from anywhere":
            "Your story is accessible from anywhere. Anybody with this link will be able to both read and edit it.",
          "Could not write to clipboard!": "Could not write to clipboard! =(",
          "Your share link": "Your sharing link",
          "Copy into clipboard": "Copy to clipboard",
          "Got any suggestions?": "Got any suggestions?",
          "Shoot us a message!": "Shoot us a message!",
          "Close the menu": "Close the menu",
          Share: "Share",
          "Share your story via a link": "Share your note via a link",
          Refresh: "Refresh",
          "Get the latest version of your story":
            "Get the latest version of your note",
          "Upload your own background": "Upload your own background",
          "Save online": "Save online",
          "Store your story on the server": "Store your note on the server",
          Fullscreen: "Fullscreen",
          "Toggle fullscreen on or off": "Toggle fullscreen on or off (F11)",
          "something went wrong, your story could not be saved":
            "Something went wrong! =( Your text could not be saved. Check your internet connection, try again in a while, and - most importantly - copy your text somewhere safe!",
          "Your story got saved.": "Your note got saved successfully..",
          "this is limya":
            "This is Limya. A simple, minimalist, pleasant to the eye notepad  to help sort your thoughts out.",
          "Heya!": "Heya!",
          "there is a book icon in the bottom left corner of your screen":
            "There is a book icon in the bottom left corner of your screen. Press it to open the menu.",
          "there you can save your story and get your storys link":
            "There, you can save your note and get your note's share link. Notes are stored safely on our servers. Only people with whom you share the link will be able to view and edit your notes.",
          "Happy writing!": "Happy writing!",
          "awesome now let me write": "Awesome! Now, let me write.",
          "ask_image_url":"Paste the URL of the image you'd like to insert."
        }
      },
      pl: {
        translation: {
          "Custom background": "Zmień tło",
          "A link, preferably ending with jpg or png":
            "Link do obrazka, .jpg, .jpeg lub .png",
          "Change background": "Zmień tło",
          "Paste the link to your custom background here":
            "Tutaj skopiuj link do twojego własnego tła. <1/> Powinien być do link do obrazka, najlepiej kończący się na <3>.jpg</3> lub <5>.png</5>",
          "your story is accessible from anywhere":
            "Twoja notka jest dostępna dla każdego, komu przekażesz poniższy link. Link uprawnia zarówno do czytania, jak i do edycji tekstu.",
          "Could not write to clipboard!":
            "Z jakiegoś powodu, nie mogliśmy zapisać linku do schowka =(",
          "Your share link": "Twój link",
          "Copy into clipboard": "Skopiuj do schowka",
          "Got any suggestions?": "Masz jakieś sugestie?",
          "Shoot us a message!": "Napisz do nas!",
          "Close the menu": "Zamknij menu",
          Share: "Podziel się",
          "Share your story via a link": "Skopiuj link szybkiego dostępu",
          Refresh: "Odśwież",
          "Get the latest version of your story": "Pobierz notkę z serwera",
          "Upload your own background": "Wgraj Twoje własne tło",
          "Save online": "Zapisz notkę",
          "Store your story on the server": "Zapisz notkę na serwerze",
          Fullscreen: "Pełny ekran",
          "Toggle fullscreen on or off": "Włącz lub wyłącz pełny ekran (F11)",
          "something went wrong, your story could not be saved":
            "Coś poszło nie tak. =( Twoja notka nie mogła zostać zapisana. Sprawdź Twoje połączenie, spróbuj ponownie za chwilę, a przede wszystkim zapisz tekst w jakimś bezpiecznym miejscu.",
          "Your story got saved.": "Twoja notka została zapisana.",
          "this is limya":
            "Witamy na Limyi. Naszym celem jest stworzenie minimalistycznego, prostego, przyjemnego dla oka notatnika, w którym nic nie będzie rozpraszało Twoich myśli.",
          "Heya!": "Hej!",
          "there is a book icon in the bottom left corner of your screen":
            "W dolnym lewym rogu Twojego ekranu widnieje ikonka książki. Kliknij na nią, by otworzyć menu.",
          "there you can save your story and get your storys link":
            "W menu możesz zapisać Twoją notkę i skopiować link szybkiego dostępu. Notki są bezpiecznie przechowywany na naszym serwerze. Tylko osoby, z którymi podzielisz się linkiem, będą mogły je czytać i edytować.",
          "Happy writing!": "Miłego pisania!",
          "awesome now let me write": "Okay!",
          "ask_image_url":"Podaj URL obrazka, który chciałbyś wstawić."
        }
      }
    },
    fallbackLng: "en-US",
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain"
      ]
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
