import React from "react";
import ReactDOM from "react-dom";
import App from "./Components/App";
import * as serviceWorker from "./serviceWorker";
import { StandardConfiguration } from "./Configurations/StandardConfiguration";
import {store} from "&redux/store";

ReactDOM.render(
  <StandardConfiguration store={store}>
    <App />
  </StandardConfiguration>,
  document.getElementById("root")
);

serviceWorker.unregister();
