import { Ui } from "&redux/store/Ui";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ui = createSlice({
  name: "ui",
  initialState: { backgroundLink: undefined },
  reducers: {
    changeBackground: (
      state,
      action: PayloadAction<{ backgroundLink: string }>
    ) => {
      state.backgroundLink = action.payload.backgroundLink;
    },
  }
});

export const requestChangeBackground = createAction<
  (args: {
    backgroundLink: string;
    storyId: string;
  }) => {
    payload: { backgroundLink: string; storyId: string };
  }
>("requestChangeBackground", args => {
  const link = !args.backgroundLink.startsWith("http")
    ? "http://" + args.backgroundLink
    : args.backgroundLink;
  return {
    payload: {
      storyId: args.storyId,
      backgroundLink: link
    }
  };
});
