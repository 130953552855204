
import { Persistent } from "&redux/store/Persistent";
import {createSlice} from "@reduxjs/toolkit";


export const persistent = createSlice({
  name: "persistent",
  initialState: {isTutorialCompleted:false},
  reducers: {
    markTutorialCompleted: (state: Persistent) => {
      state.isTutorialCompleted = true;
    }
  }
});


export const {markTutorialCompleted} = persistent.actions;
